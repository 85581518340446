import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [];

export const dictionary = {
		"/": [8],
		"/auth": [38,[7]],
		"/auth/goodbye/[lang]": [39,[7]],
		"/auth/login": [40,[7]],
		"/auth/password": [41,[7]],
		"/auth/password/success": [43,[7]],
		"/auth/password/[token]": [42,[7]],
		"/(app)/board": [9,[2]],
		"/(public)/confirm_email/[token]": [30],
		"/(app)/integrations": [10,[2]],
		"/(app)/integrations/notion": [11,[2]],
		"/(app)/integrations/webhooks": [12,[2]],
		"/(app)/integrations/zapier": [13,[2]],
		"/(public)/maintenance": [31],
		"/(app)/notes": [14,[2,3]],
		"/(app)/notes/creating": [15,[2,3]],
		"/(app)/notes/new": [16,[2,3]],
		"/(app)/settings": [17,[2]],
		"/(public)/setup": [32,[6]],
		"/(public)/setup/finish": [33,[6]],
		"/(public)/setup/join": [34,[6]],
		"/(public)/setup/join/guest": [35,[6]],
		"/(public)/setup/plan": [36,[6]],
		"/(public)/setup/user-info": [37,[6]],
		"/(app)/tags": [18,[2]],
		"/(app)/templates": [19,[2]],
		"/(app)/templates/new": [21,[2]],
		"/(app)/templates/[id]": [20,[2]],
		"/(app)/v/id/[id]": [23,[2,4,5]],
		"/(app)/v/id/[id]/chat": [24,[2,4,5]],
		"/(app)/v/id/[id]/notes": [25,[2,4,5]],
		"/(app)/v/id/[id]/notes/creating": [27,[2,4,5]],
		"/(app)/v/id/[id]/notes/new": [28,[2,4,5]],
		"/(app)/v/id/[id]/notes/[id_note]": [26,[2,4,5]],
		"/(app)/v/id/[id]/transcript": [29,[2,4,5]],
		"/(app)/v/[...folder]": [22,[2,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';